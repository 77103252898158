.parallel_items {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.parallel_items .left_item,
.parallel_items .right_item {
  flex: 0 1 49%;
}

.parallel_items .left_item img,
.parallel_items .right_item img {
  width: 100%;
  border-radius: 20px;
}

.parallel_items .left_item > *,
.parallel_items .right_item > * {
  border-radius: 20px;
  box-shadow: 2px 1px 18px 0 rgb(34 60 80 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallel_items .right_item {
  text-align: right;
}

@media (max-width: 991px) {
  .parallel_items {
    padding: 0 10px;
  }
}

@media (max-width: 575px) {
  .parallel_items {
    flex-direction: column;
  }

  .parallel_items .left_item {
    margin-bottom: 15px;
  }

  .parallel_items .left_item,
  .parallel_items .right_item {
    flex: 0 1 auto;
  }
}
